import $ from 'jquery';
import AjaxHelper from './AjaxHelper';
const GOOGLE_FONTS_URL = 'https://fonts.googleapis.com/css?family=';

export default class GraverManager
{
    constructor() {
        this.AjaxHelper = new AjaxHelper();
        this.initEvents();
    }

    initEvents() {
        this.addSubmitToButton();
        this.addChangeToSelect();
        this.addChangeToFileUploader();
        this.removeFileFromUploader();
        this.addChangeFontsSelect();
        // this.addCharNum();
    }

    addSubmitToButton() {
        let that = this;
        let submitButton = '.js-graver-save-customization';
        $(document).on('click', submitButton, function (e) {
            e.preventDefault();
            that.saveGraver();
        });
    }

    addChangeToSelect() {
        let selectGraverType = '.js-select-graver-type';
        let graverItem = '.graver__item';
        let componentsData = '[data-graver]';
        let that = this;
        $(document).on('change', selectGraverType, function () {
            let selectVal = parseInt($(this).val());
            let components = $(this).parents(graverItem).find(componentsData);
            let visibleClass = 'visible';
            components.removeClass(visibleClass);
            components.each(function(index) {
                let componentDataGraver = $(this).data('graver');
                if(selectVal & componentDataGraver){
                    $(this).addClass(visibleClass);
                }
            });
            if(selectVal & 1){
                let select = '.js-graver-select-font-family';
                let textInputWrapper = '.js-graver-text-input-wrapper';
                let textInput = '.js-graver-text-input';
                let $select = $(this).parents(graverItem).find(select);

                that.fontManager($select);

                $select
                  .parents(textInputWrapper)
                  .find(textInput)
                  .css('font-family', $select.find(':selected').text());
            }
        })
    }

    addChangeToFileUploader() {
        let fileUploaderInput = '.js-graver-input-file-upload';
        let fileUploaderIcon = '.js-graver-input-file-icon';
        let fileUploaderLabel = '.js-graver-input-file-label';
        let fileUploaderWrapper = '.js-graver-input-file';
        let fileUploaderRemove = '.js-graver-input-file-remove';
        $(document).on('change', fileUploaderInput, function() {
            let parents = $(this).parents(fileUploaderWrapper);
            if(parents.find(fileUploaderLabel).hasClass('selected')) {
                parents.find(fileUploaderIcon).addClass('selected');
                parents.find(fileUploaderRemove).addClass('visible');
            }
        });
    }

    removeFileFromUploader() {
        let fileUploaderRemove = '.js-graver-input-file-remove';
        let fileUploaderWrapper = '.js-graver-input-file';
        let fileUploaderInput = '.js-graver-input-file-upload';
        let fileUploaderIcon = '.js-graver-input-file-icon';
        let fileUploaderLabel = '.js-graver-input-file-label';
        $(document).on('click', fileUploaderRemove, function() {
            let parents = $(this).parents(fileUploaderWrapper);
            let input = parents.find(fileUploaderInput);
            let label = parents.find(fileUploaderLabel);
            let icon = parents.find(fileUploaderIcon);
            input.replaceWith(input.val('').clone(true));
            label.html(label.data('label-text')).removeClass('selected');
            icon.removeClass('selected');
            $(this).removeClass('visible');
        });
    }

    addCharNum() {
        let textInput = '.js-graver-text-input';
        let textInputWrapper = '.js-graver-text-input-wrapper';
        let inputChar = '.js-graver-input-char';
        $(document).on('input', textInput, function() {
            let max = $(this).attr('maxlength');
            let len = $(this).val().length;
            if(len === 0){
                $(this)
                    .parents(textInputWrapper)
                    .find(inputChar)
                    .text('');
            } else {
                $(this)
                    .parents(textInputWrapper)
                    .find(inputChar)
                    .text(len + '/' + max);
            }
        });
    }

    addChangeFontsSelect() {
      let select = '.js-graver-select-font-family';
      let that = this;

      $(document).on('change', select, function () {
        let $select = $(this);
        let textInputWrapper = '.js-graver-text-input-wrapper';
        let textInput = '.js-graver-text-input';

        that.fontManager($select);

        $select
          .parents(textInputWrapper)
          .find(textInput)
          .css('font-family', $select.find(':selected').text());
      });
    }

    fontManager(select) {
        let $select = select;

        let fontID = $select.find(':selected').data('font-name');
        if (!document.getElementById(fontID)) {
            let head = document.getElementsByTagName('head')[0];
            let link = document.createElement('link');
            link.id = fontID;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = GOOGLE_FONTS_URL + fontID + '&display=swap&subset=latin-ext';
            link.media = 'all';
            head.appendChild(link);
        }
    }

    saveGraver() {
        let div = $('#graver');
        let formData = new FormData(div.closest('form')[0]);
        let url = div.data('action');
        let data = formData;
        this.AjaxHelper.sendRequest(url, data, 'manageResponse', this);
    }

    manageResponse(response) {
        let inputSumitStatus = $('.js-graver-submit-status');
        let productCustomizationIdInput = $('#product_customization_id');
        let saveGraverSuccesModal = $('.js-graver-save-success');
        let saveGraverErrorModal = $('.js-graver-save-error');
        let addToCartButton = $('.js-add-to-cart');
        if(response.success) {
            inputSumitStatus.val(1);
            productCustomizationIdInput.val(response.id_customization);
            // saveGraverSuccesModal.modal('show');
          addToCartButton.click();
        } else {
            inputSumitStatus.val(0);
            let errors = [];
            for (var indexError in response.errors) {
                if (Array.isArray(response.errors[indexError])){
                    for (var index in response.errors[indexError]){
                        errors.push(response.errors[indexError][index]);
                    }
                } else {
                    errors.push(response.errors[indexError]);
                }
            }
            saveGraverErrorModal.find('.modal-text').html(errors.join('</br>'));
            saveGraverErrorModal.modal('show');
        }
    }
}

