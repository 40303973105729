$(document).ready(function () {
  $(document).on('click', '.js-variant-refresh', function (e) {
    e.preventDefault();

    var miniature = $(this).closest('.product-miniature');

    $.ajax({
      url: $(this).data('action'),
      dataType: 'json',
      success: function (response) {
        miniature.replaceWith(response.html);
      }
    });
  });
});
