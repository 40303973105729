import GraverMaganer from './components/GraverManager';
import GraverFontsPopup from './components/GraverFontsPopup';

$(function () {
    const graverManager = new GraverMaganer();
    const graverFontsPopup = new GraverFontsPopup();

    $('[id*="fonts-graver"].modal').on('shown.bs.modal', function () {
      if (!prestashop.responsive.mobile) {
        $('.zoomple').zoomple({
          source: 'rel',
          blankURL: '../themes/starter/assets/img/blank.gif',
          loaderURL: '../themes/starter/assets/img/loader.gif',
          bgColor : '#fff',
          offset : {x:-250,y:-250},
          zoomWidth : 500,
          zoomHeight : 500,
          roundedCorners : true,
          symbolId: 'data-id-symbol',
          graverId: 'data-id-graver',
          graverSymbolsWrapper: '.js-graver-symbols-wrapper',
          activeClass: 'checked'
        });
      }
    });
});
