window.validateAgreements = function(e, callback, object = null) {

    e.preventDefault();

    if (e.currentTarget.nodeName.toLowerCase() === 'form') {
        var form = $(e.currentTarget);
    } else {
        var form = $(e.currentTarget).closest('form');
    }

    var div = form.find('.wnet_agreements-form');
    var inputs = div.find('input');
    var formData = inputs.serialize() + '&email=' + encodeURIComponent(form.find('input[type="email"]').val());

    $.ajax({
        type: 'POST',
        url: div.data('action'),
        async: true,
        dataType: 'json',
        data: formData,
        success: function(response) {
            if (!response.success) {
                inputs.each(function () {
                    $(this).closest('.form-group').addClass('was-validated')
                });
                return false;
            }
            if (typeof callback === 'string' && object) {
                object[callback]();
            }
            else if (typeof callback === 'function') {
                callback();
            } else {
                form.off('submit');

                var submitButtons = form.find('input[type="submit"]');
                if(submitButtons.length === 1) {
                  submitButtons.click();
                } else {
                  form.submit();
                }
            }
        },
    });
};

$(document).ready(function() {
    var agreementsForm = $('.wnet_agreements-form').parents('form');

    agreementsForm.on('submit', function(e) {
        if(!$(this).hasClass('js-emailsubscription-form')) {
            window.validateAgreements(e);
        }
    });
});
