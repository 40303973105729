import Cookies from 'js-cookie';

export default class CookiesHeaderStripe{

    constructor(selector) {
        this.element = $(selector);
    }

    show() {
        $(this.element).show();
    }

    hide() {
        $(this.element).hide();
        Cookies.set('cookies-header-stripe-hidden', 1);
    };
}
