import CookiesBar from './CookiesBar';

(function($){
  const cookiesBar = new CookiesBar('.wnet_cookiespolicy');
  $(document).on('click', '.js-hide-cookies-info', function(e){
      e.preventDefault();
      cookiesBar.hide();
  });
  $(document).on('click', '.js-remove-cookies-info', function(e){
    e.preventDefault();
    $('.wnet_cookiespolicy').remove();
  });
})(jQuery);
