import SwiperSlider from './SwiperSlider';

export default class VariantsGallery{
  init(){
    this.initSliders();
  }

  initSliders(){

    this.galleries = new SwiperSlider({
      slidesPerView: 20,
      breakpoints: {
        1920: {
          slidesPerView: 14
        },
        1199: {
          slidesPerView: 14
        },
        991: {
          slidesPerView: 10
        },
        575: {
          slidesPerView: 10
        },
        500: {
          slidesPerView: 9
        },
        450: {
          slidesPerView: 8
        },
        400: {
          slidesPerView: 6
        }
      }
    });

    this.gallery = this.galleries.init('.product-variants-color-carousel', {
      on: {
        init: function () {
          this.slideTo(this.$el.find('input:checked').closest('.swiper-slide').index(), 0);
        },
      },
    });
  }
}
