const optionsSets = {
    itemInterval: 4500,
    currentItem: 1
};

export default class TabChanger {
    init() {
        let itemInterval = optionsSets.itemInterval;
        let currentItem = optionsSets.currentItem;
        let items = $('.products-slider-home a[data-toggle="tab"]');
        let numberOfItems = items.length;

        this.infiniteLoop = setInterval(function () {
            $(items).eq(currentItem).tab('show');

            if (currentItem === numberOfItems - 1){
                currentItem = 0;
            } else {
                currentItem++;
            }

        }, itemInterval);
    }

    stop() {
        if(this.infiniteLoop){
            clearInterval(this.infiniteLoop);
            this.infiniteLoop = null;
        }
    }
}
