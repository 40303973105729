import './custom.jquery.auto-complete';

$(document).ready(function () {
    var $searchWidget = $('[data-search-widget]'),
        $searchBox    = $searchWidget.find('input[type=text]'),
        searchURL     = $searchWidget.attr('data-search-controller-url');


    $searchBox.autoComplete({
        minChars: 3,
        appendTo: '.search-widget',
        source: function (query, response) {
            $.post(searchURL, {
                s: query,
                resultsPerPage: 3
            }, null, 'json')
                .then(function (resp) {
                    window.jQueryAutocompleteResponseitemCount = resp.pagination.total_items;
                    response(resp.products);
                })
                .fail(response);
        },
        renderItem: function (product, search) {
            return `
<div class="autocomplete-product autocomplete-suggestion" data-url="${product.url}">
  <div class="autocomplete-product__image-container">
    <img class="autocomplete-product__image" src="${product.cover.bySize.small_default.url}" alt="">
  </div>
  <div class="autocomplete-product__data-container">
    <span class="autocomplete-product__name">
       ${product.name}
    </span>
    <span class="autocomplete-product__price-container">
       <span class="autocomplete-product__price autocomplete-product__price--old">
       </span>
       <span class="autocomplete-product__price">
          ${product.regular_price}
       </span>
    </span>
  </div>
</div>
      `;
        },
        onSelect: function (e, term, item) {
            e.preventDefault();
            window.location.href = item.data('url');
        },
        lastItem: function(){
            return '<a href="#" class="autocomplete-product__last-text">'+$('.search-widget .form-control').data('last-item-text').replace('[1]', window.jQueryAutocompleteResponseitemCount)+'</div>';
        },
    });

    $(window).on("scroll", function(){
        $('.autocomplete-suggestions:visible').hide();
    });

    $('#searchModal').on('hide.bs.modal', function () {
        $('.autocomplete-suggestions:visible').hide();
    });

    $(document).on('click', '.autocomplete-product__last-text', function(e){
        e.preventDefault();
        console.log('eheheh');
        $('.search-widget form').submit();
    });
});
