import SwiperSlider from 'theme/js/components/SwiperSlider';
import TabChanger from './components/TabChanger';

(($) => {
  const swiperSlider = new SwiperSlider();
  const featuredTabs = new TabChanger();
  const initSlider = (container) => {
    return swiperSlider.init(container, {
      loop: ($(container).find('.swiper-slide').length > 4),
      slidesPerView: 4,
      spaceBetween: 25,
      autoplay: {
        delay: 1000
      },
      navigation: {
        nextEl: item => $(item).closest('.products-slider-home__tab-pane').find('.js-featured-tabs-products-slider-next'),
        prevEl: item => $(item).closest('.products-slider-home__tab-pane').find('.js-featured-tabs-products-slider-prev'),
      },
      breakpoints: {
        1400: {
          slidesPerView: 3,
          spaceBetween: 25
        },
        991: {
          slidesPerView: 2,
          spaceBetween: 25
        },
        576: {
          slidesPerView: 2,
          spaceBetween: 10
        }
      },
      on: {
        touchMove(event){
          featuredTabs.stop();
        }
      }
    });
  };

  initSlider('.js-featured-tabs-products-slider:visible');

  featuredTabs.init();
  $(document).on('shown.bs.tab', '.products-slider-home a[data-toggle="tab"]', (e) => {
    let tabPane = $($(e.currentTarget).attr('href') + ' .js-featured-tabs-products-slider');
    const activeSlider = tabPane[0].swiper;
    if(tabPane.hasClass("swiper-container-initialized")){
      activeSlider.destroy();
    }
    initSlider(tabPane);
  });


  $(document).on('click', '.products-slider-home li.products-slider-home__nav-item, .js-product-miniature', (e) => {
    featuredTabs.stop();
    swiperSlider.swipers.forEach((item) => {
      if(typeof item.autoplay !== 'undefined'){
        item.autoplay.stop();
      }
    });
  });

  $(document).on('click', '.products-slider-home__tab-pane .swiper-button-prev, .products-slider-home__tab-pane .swiper-button-next', (e) => {
    featuredTabs.stop();
  });
})(jQuery);
