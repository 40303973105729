import $ from 'jquery';
import YouTubePlayer from './YouTubePlayer';
import YoutubeAPI from './youtube-api';

export default class SliderYoutubeController {
    /**
     * @param {Swiper} $slider
     */
    constructor(slider) {
        /**
         * @type Swiper
         */
        this.slider = slider;
        /*
         * @type {jQuery|HTMLElement}
         */
        this.$slider = $(slider.el);
        if (YoutubeAPI.isReady()) {
            this.constructorDeferred(slider);
        } else {
            $(document).on('youtube-api-initialized', () => {
                this.constructorDeferred(slider);
            });
        }
    }

    /**
     * @param {Swiper} $slider
     */
    constructorDeferred(slider) {
        this.$slider = $(slider.el);
        this.slider.on('transitionEnd', () => {
            this.changeAction(new Event('transitionEnd'));
        });
        this.changeAction(new Event('dummy-event'));
    }



    /**
     * @param {Event} event
     * @param {jQuery} slider
     * @param {Number} prevSlideIndex - prev slide index
     * @param {Number} nextSlideIndex - next slide index
     */
    changeAction(event) {
        this.destroyAll();
        this.constructPlayer(this.getCurrentSlide());
    }


    /**
     * @param {Number} i
     */
    getSlideByIndex(i) {
        return $(this.slider.slides[i]);
    }

    getCurrentSlide() {
        return this.slider.activeIndex;
    }
    /**
     * @param {null|Number} i - if null then current is taken
     */
    getPlayerData(i = null) {
        if (i === null) {
            i = this.getCurrentSlide();
        }
        return this.getSlideByIndex(i).find('[data-youtube]');
    }

    destroyAll() {
        this.$slider.find('[data-youtube]').each((i, thatPlayer) => {
            // destroy somehow
            this.destroyPlayer($(thatPlayer));
        });
    }

    /*
     * @param {jQuery} thatPlayer
     */
    destroyPlayer(thatPlayer) {
        thatPlayer.parent().find('iframe').remove();
    }

    /**
     * @param {Number} slideIndex
     */
    constructPlayer(slideIndex) {
        let $playerDataElement = this.getPlayerData(slideIndex);
        if (!$playerDataElement.length) {
            return;
        }
        let player = new YouTubePlayer($playerDataElement);
        player.enable();
        $playerDataElement.parent().find('iframe').addClass('imageslider__yt-iframe');
    }
}
