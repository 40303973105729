$(document).ready(function () {
    var loginForm =$('.popup-login-form');

    $('.js-sign-in').on('click', function () {
      var getFormUrlContainer = $('#popup-login-get-form-container');

      if(getFormUrlContainer.length) {
        $.ajax({
          url: getFormUrlContainer.data('action'),
          dataType: 'json',
          success: function (response) {
            loginForm.html(response.html);
          }
        });
      }
    });

    $(document).on('submit', '#login-form', function (e) {
      // check if in ajax context
      if($(this).attr('action').indexOf('ajax') !== -1) {
        // alert($(this).attr('action').indexOf('ajax'));
        e.preventDefault();

        $.ajax({
          url: $(this).attr('action'),
          method: 'POST',
          data: $(this).serialize(),
          dataType: 'json',
          success: function (response) {
            if(response.success) {
              // ps_customersignin
              $('.customer-sign-in__wrapper').replaceWith(response.html);

              $('.ps-alert-success').html(response.message).toggleClass('d-none');

              setTimeout(function () {
                $('#popup-login').find('.close').click()
              }, 1000);

              prestashop.customer.is_logged = true;

              $('.text-logged').removeClass('d-none');
              $('.text-unlogged').addClass('d-none');

            } else {
              console.log(response.html);
              loginForm.html(response.html);
            }
          }
        });
      }
    })
});
