const optionsSets = {
    background: {
        autoplay: 0,
        autohide: 1,
        modestbranding: 0,
        rel: 0,
        showinfo: 0,
        controls: 0,
        disablekb: 1,
        enablejsapi: 0,
        iv_load_policy: 3
    },
    triggered: {
        autoplay: 1,
        autohide: 1,
        modestbranding: 0,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3
    },
    plain: {},
};

export default class YouTubePlayer {
    /**
     * @param $playerDataElement
     * @param {"plain"|"background"|"triggered"} type
     */
    constructor($playerDataElement, type = "background") {
        this.playerDataElement = $playerDataElement;
        this.extractVideoId();
        if (typeof optionsSets[type] === 'object') {
            this.playerDefaults = optionsSets[type];
        } else {
            this.playerDefaults = optionsSets.plain;
        }
    }

    extractVideoId() {
        let url = this.playerDataElement.attr('data-youtube');
        url = url.split('/');
        this.videoId = url.pop();
    }

    enable() {
        this.wrapper = this.playerDataElement.parent();
        const playerSeed = $('<span/>');
        this.wrapper.prepend(playerSeed);
        this.player = new YT.Player(
            playerSeed.get(0),
            {
                events: {
                    'onReady': (ev) => this.onPlayerReady(ev),
                    'onStateChange': (ev) => this.onPlayerStateChange(ev)
                },
                playerVars: this.playerDefaults
            }
        );
        return this;
    }

    onPlayerReady(ev) {
        ev.target.loadVideoById(this.videoId);
        ev.target.playVideo();
        ev.target.mute();
    }

    onPlayerStateChange(ev) {
        if (ev.data === 0) {
            ev.target.loadVideoById(this.videoId);
            ev.target.playVideo();
            ev.target.mute();
        }
    }

    getPlayer() {
        return this.player;
    }
}