import $ from 'jquery';

export default class GraverFontsPopup
{
    constructor() {
        this.initEvents();
    }

    initEvents() {
        this.clickSymbol();
    }

    clickSymbol() {
        let that = this;
        let symbols = '.js-graver-symbol';

        $(document).on('click', symbols, function() {
           let symbol = this;
           that.selectSymbol(symbol);
           that.updateSelectedSymbolValue(symbol);
        });

        $(document).on('click', '.js-graver-symbols-zoom', function() {
            let symbol = this;
            let checkedSymbolFromZoom = that.selectSymbolFromZoom(symbol, symbols);
            that.selectSymbol(checkedSymbolFromZoom);
            that.updateSelectedSymbolValue(checkedSymbolFromZoom);
        });
    }

    selectSymbolFromZoom(symbol, symbols){
        let symbolId = $(symbol).attr('data-id-symbol');
        let graverId = $(symbol).attr('data-id-graver');
        let symbolsWrapper = $(`.js-graver-symbols-wrapper[data-id-graver=${graverId}]`);
        let checkedSymbol = symbolsWrapper.find(`${symbols}[data-id-symbol=${symbolId}]`);
        let $checkedSymbol = $(checkedSymbol)[0];

        return $checkedSymbol ? $checkedSymbol : null;
    }

    selectSymbol(symbol) {
        let symbolsWrapper = '.js-graver-symbols-wrapper';
        let symbols = $(symbol).parents(symbolsWrapper).find('.js-graver-symbol');
        let zoomImageWrap = `#zoomple_previewholder[data-id-symbol=${$(symbol).attr('data-id-symbol')}] .image_wrap`;
        symbols.not(symbol).removeClass('checked');
        $(symbol).toggleClass('checked');
        $(zoomImageWrap).toggleClass('checked');
    }

    updateSelectedSymbolValue(that) {
        let symbolsWrapper = '.js-graver-symbols-wrapper';
        let symbol = $(that).parents(symbolsWrapper).find('.js-graver-symbol.checked');
        let currentIdGraver = $(that).parents(symbolsWrapper).data('id-graver');
        let id_symbol = symbol.length ? symbol.data('id-symbol') : null;
        let symbol_name = symbol.length ? symbol.data('symbol-name') : null;
        let symbolGraverValue = $(`.js-graver-symbol-id-${currentIdGraver}`);
        let symbolGraverLabelWrapper = $(`.js-graver-symbol-label-${currentIdGraver}`);
        let symbolGraverLabel = symbolGraverLabelWrapper.data('label-text');

        if (id_symbol !== null){
            symbolGraverValue.val(id_symbol);
            symbolGraverLabelWrapper.html(symbol_name);
        } else {
            symbolGraverValue.val('');
            symbolGraverLabelWrapper.html(symbolGraverLabel);
        }
    }
}

