import SwiperSlider from './SwiperSlider';

export default class ProductGallery{
  init(){
    this.initSliders();
  }

  initSliders(){
    this.swiperSlider = new SwiperSlider({
      loopedSlides: 3,
      loop: false,
    });

    this.thumbs = this.swiperSlider.init('.product-thumbs', {
      slidesPerView: 4,
      spaceBetween: 7,
      slideToClickedSlide: true,
      direction: 'vertical',
      navigation: {
        nextEl: '.js-product-thumbs-next',
        prevEl: '.js-product-thumbs-prev',
      },
      breakpoints: {
        1199: {
          slidesPerView: 4,
        }
      }
    });

    this.covers = this.swiperSlider.init('.products-imagescover', {
      pagination: {
        el: '.swiper-pagination_covers',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next_covers',
        prevEl: '.swiper-button-prev_covers',
      },
      on: {
        init: function () {
          if ($('#player').length) {
            $('.product-flags, .product_icons, .btn-zoom').attr('style', 'display: none;');
          }
        },
        slideChange: function () {
          if ($('#player').length && !$(this.slides[this.activeIndex]).hasClass('video-slide') && player) {
            $('.product-flags, .product_icons, .btn-zoom').removeAttr('style');
            player.pauseVideo();
          } else if ($(this.slides[this.activeIndex]).hasClass('video-slide')) {
            $('.product-flags, .product_icons, .btn-zoom').attr('style', 'display: none;');
          }
        },
      }
    });

    if(this.covers.length && this.thumbs.length) {
      this.covers[0].on('slideChange', () => {
        this.thumbs[0].slideTo(this.covers[0].activeIndex, 200);

        $(this.thumbs[0].wrapperEl).find('.product-thumb').removeClass('selected');
        $(this.thumbs[0].slides[this.covers[0].activeIndex]).find('.product-thumb').addClass('selected');
      });

      this.thumbs[0].on('click', (el) => {
        this.covers[0].slideTo(this.thumbs[0].clickedIndex, 200);
      });

      this.thumbs[0].on('slideChange', () => {
        this.covers[0].slideTo(this.thumbs[0].activeIndex, 200);
      });
    }
  }
}
