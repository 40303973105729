/* global $, prestashop */
$(document).ready(function () {
  prestashop.on('updateCart', () => {
    var div = $('#products-under-x-summary');

    $.ajax({
      type: 'POST',
      url: div.data('refresh-url'),
      dataType: 'json',
      async: true,
      success: function (response) {
        div.replaceWith(response.html);
      }
    });
  });
});
