// import $ from 'jquery';
export default class AjaxHelper
{
    sendRequest(url, data, callback, obj) {
        $.ajax({
            url: url,
            method: 'POST',
            data: data,
            dataType: 'json',
            processData: false,
            contentType: false,
            success:function(data) {
                obj[callback](data);
            }
        });
    }
}
