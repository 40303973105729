$(document).ready(() => {
    let avatar = '.js-avatar-img';
    let avatarInput = '#avatar-input';
    let $avatarInput = $(avatarInput);
    let $form = $('#avatar-form');
    let $avatarImg = $('#avatar-img');

    $(document).on('click', avatar, ()=> {
        $avatarInput.trigger('click');
    });

    $(document).on('change', avatarInput, (e) => {
        e.preventDefault();
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            async: true,
            data: new FormData($form[0]),
            dataType: 'json',
            contentType: false,
            processData: false,
            success: (response) => {
                if(response.success) {
                    $avatarImg.attr('src', response.src);
                } else {
                    alert(response.error);
                }
            }
        });
    });
});
