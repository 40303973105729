/* global $, prestashop */
$(document).on('click', '.js-gift-wrap-checkbox-cart', function (e) {
  $.ajax({
    method: 'POST',
    url: $(this).data('submit-url'),
    data: $(this).serialize(),
    dataType: 'json',
    async: true,
    success: (response) => {
      if (response.success) {
        prestashop.emit('updateCart', {reason: {cart: prestashop.cart}});
      }
    },
  });
});

prestashop.on('updateCart', function (e) {
  var checkbox = $('#js-gift-wrap-checkbox');

  var reason = e.reason;
  var urlParams = new URLSearchParams(checkbox.data('submit-url'));

  if(reason.linkAction == 'add-to-cart' && reason.idProduct == urlParams.get('id_product') && reason.idProductAttribute == urlParams.get('id_product_attribute')) {
    $.ajax({
      method: 'POST',
      url: checkbox.data('submit-url'),
      data: {
        id_customization: reason.idCustomization,
        add_gift_wrap: checkbox.prop('checked') ? 1 : 0,
      },
      dataType: 'json',
      async: true,
      success: (response) => {
        if (response.success) {
          prestashop.emit('updateCart', {reason: {cart: prestashop.cart}});
        }
      },
    });
  }
});
//
// $('.js-gift-wrap-checkbox').on('click', function(e) {
//   $('.gift-wrap-form').attr('action', $(this).data('submit-url'));
// });
//
//
// $('#gift-wrap-product-form').on('submit', function(e) {
//   e.preventDefault();
// });
//
// $('#gift-wrap-cart-form').on('submit', function(e) {
//   e.preventDefault();
//
//   $.ajax({
//     method: 'POST',
//     url: $(this).attr('action'),
//     data: $(this).serialize(),
//     dataType: 'json',
//     async: true,
//     success: (response) => {
//       if(response.success) {
//         prestashop.emit('updateCart', {reason: {cart: prestashop.cart}});
//         $('#gift-message').val('');
//       } else {
//
//       }
//     },
//   });
// });
//
