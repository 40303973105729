import $ from "jquery";

export default class YoutubeAPI {
    static initialize() {
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/player_api';
        $('head').append(tag);
        window.onYouTubePlayerAPIReady = function() {
            $(document).trigger('youtube-api-initialized');
            window.youtubeAPIReady = true;
        };
    }

    static isReady() {
        return typeof window.youtubeAPIReady !== 'undefined' && window.youtubeAPIReady;
    }
}